$font-family: "Inter", sans-serif;
$font-family-mono: "Roboto Mono", sans-serif, monospace;

$background-color: #19032d;
$text-color: #fff;

$primary-purple: #8716e8;
$alternate-pink: #fe6e99;

///
/// SPACING
///
$spacing-xs: 0.5rem;
$spacing-s: 0.75rem;
$spacing-m: 1rem;
$spacing-l: 1.25rem;
$spacing-xl: 1.5rem;
