@import "styles/variables";

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: $background-color;
  z-index: 100;

  & .contentContainer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 2rem 0;

    & .content {
      width: 100%;
      max-width: 1240px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      z-index: 300;
    }
  }
}

.fullWidth {
  width: 100%;
}

.noise {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./assets/images/noise.png");
  background-repeat: repeat;
  opacity: 0.4;
  mix-blend-mode: screen;
  pointer-events: none;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.stepCompleted {
  color: $alternate-pink;
}

.stepIncomplete {
  color: transparentize($color: $text-color, $amount: 0.5);
}

@media only screen and (max-width: 820px) {
  .app {
    display: none;
  }
}

.forefront {
  z-index: 100;
}
