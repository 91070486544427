@import "../../styles/variables";

.itemRow {
  background-color: darken($color: $primary-purple, $amount: 35%);
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &.preview {
    background-color: transparent;
  }
}

.protocolTeam {
  & .completed {
    & h1,
    & h2,
    & h3,
    &h4 {
      color: $alternate-pink;
    }
  }
}
