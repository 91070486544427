@import "../../../styles/variables";

.settingsSection {
  border: 1px solid darken($primary-purple, 30%);
  padding: 2rem;
  width: 100%;
  // display: flex;
  // flex-direction: column;

  input {
    width: 100%;
  }
}
