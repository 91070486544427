.title {
  color: #fff;
  display: flex;
  align-items: center;
}

h1.title {
  font-weight: 700;
  font-size: 1.5rem;
}

h2.title {
  font-weight: 700;
  font-size: 1.3rem;
}

h3.title {
  font-weight: 600;
  font-size: 1rem;
}

h4.title {
  font-weight: 600;
  font-size: 0.8rem;
}
