@import "../../styles/variables";

.footer {
  height: 80px;
  width: 100%;
  position: relative;
  z-index: 200;

  box-sizing: border-box;
  flex-shrink: 0;

  & img {
    width: 100%;
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    bottom: 77px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  & .bottom {
    width: 100%;
    height: 80px;
    position: absolute;

    background-image: linear-gradient(180deg, rgba(135, 22, 232, 0.72), hsla(0, 0%, 100%, 0));
  }

  & .content {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0;

    .contentContainer {
      width: 100%;

      div {
        padding: 0 $spacing-m;
        flex: 1;
      }

      p {
        color: hsla(0, 0%, 100%, 0.59);
        letter-spacing: 0.1em;
      }

      .links {
        a {
          svg {
            fill: lighten($color: $primary-purple, $amount: 30%);
            transition: 0.2s all ease;
          }

          &:hover {
            svg {
              fill: lighten($color: $primary-purple, $amount: 80%);
            }
          }
        }
      }
    }
  }
}
