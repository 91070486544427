@import "../../styles/variables";

.fileDrop {
  input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
  position: relative;

  width: 100%;
  height: 3.5rem;
  background-color: $primary-purple;
  border: 3px solid #000;
  box-sizing: border-box;

  font-family: $font-family;
  padding: 0 0.5rem;
  color: #fff;

  &[disabled] {
    opacity: 0.5;
  }
}
