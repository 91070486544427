@import "../../../styles/variables";

.tasks {
  & tbody tr {
    &.completed {
      color: $alternate-pink;
    }

    &:not(.active) {
      opacity: 0.5;
    }
  }
}
