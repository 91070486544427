.logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.logoBg {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.contestsTable {
  table-layout: fixed;
  width: 100%;

  & th {
    text-align: left;

    &:nth-child(1) {
      width: 1rem;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 25%;
    }
    &:nth-child(4) {
      width: 40%;
    }
    &:nth-child(5) {
      width: 12rem;
      text-align: center;
    }
  }
}

.finishedContestsTable {
  table-layout: fixed;
  width: 100%;

  & th {
    text-align: left;

    &:nth-child(1) {
      width: 1rem;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 40%;
    }
    &:nth-child(5) {
      width: 10rem;
      text-align: center;
    }
  }
}

.mono {
  font-family: monospace;
}

.warning {
  color: #ffcc00;
}

.warningBox {
  border: 1px solid #ffcc00;
  padding: 1rem;
  background-color: transparentize($color: #ffcc00, $amount: 0.9);
  color: #ffcc00;
}

.telegram {
  opacity: 0.6;
  display: flex;
  font-weight: bold;

  & *:first-child {
    margin-right: 5px;
  }
}

.filterContainer {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  transition: all 0.1s linear;
  color: rgb(156, 163, 175);

  &:hover {
    color: rgb(209, 213, 219);
  }

  .activeFilter {
    background-color: rgb(209, 213, 219);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 4px 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
}

.filtersList {
  display: flex;
  flex-direction: column;

  div {
    cursor: pointer;
    padding: 16px 32px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
