@import "../../styles/variables";

.container {
  position: relative;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: transparentize($color: $background-color, $amount: 0.4);
  backdrop-filter: blur(5px);

  pointer-events: auto;

  z-index: 100;
}

.loadingIcon {
  position: sticky;
  top: 50%;
}
