@import "../../styles/variables";

.container {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: stretch;
  align-self: stretch;
  flex-shrink: 0;

  .leftArea,
  .rightArea {
    flex-basis: 10%;
    flex-grow: 0;
  }

  .centerArea {
    flex: 1;
  }

  .centerArea {
    a {
      padding: 0 1rem;
      text-decoration: none;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      color: white;
      display: flex;
      align-items: baseline;

      & > *:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}

.logo {
  border-radius: 50%;
}
