@import "../../styles/variables";

.stakingContainer {
  width: 580px;
}

.rewardsContainer {
  background-color: transparentize($color: $primary-purple, $amount: 0.7);
  padding: 1em;

  & hr {
    width: 100%;
    height: 1px;
    background-color: $primary-purple;
    border: 0;
  }
}

.v1 {
  color: rgba(255, 255, 255, 0.8);

  a {
    color: inherit;
    transition: 0.2s all ease;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

.warning {
  background-color: $alternate-pink;
  border: 1px solid red;
  padding: 1rem;
}

.infoBanner {
  background-color: transparentize($color: $primary-purple, $amount: 0.7);
  border: 1px solid $alternate-pink;
  padding: 1em;
}
