@import "../../styles/variables";

.container {
  width: 100%;

  & th {
    padding-left: 1em;
  }

  .logo {
    border-radius: 50%;
  }

  & .logoColumn {
    width: 90px;
  }

  & .contestColumn {
    width: 40%;
  }
}

.finishedContest {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.c4Banner {
  background-color: transparentize($color: $primary-purple, $amount: 0.7);
  padding: 1em;
}

.formContainer {
  max-width: 520px;
}
