.stack {
  max-height: 40vh;
  overflow: hidden;

  pre {
    font-size: 0.725rem;
    color: rgba(255, 255, 255, 0.8);
  }
}

.eventId {
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.8);
}
