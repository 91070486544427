@import "../../../styles/variables";

.questions {
  white-space: pre-wrap;

  & .completed {
    & h1,
    & h2,
    & h3,
    &h4 {
      color: $alternate-pink;
    }
  }
}
