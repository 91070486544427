@import "../../styles/variables";

.rewardsContainer {
  background-color: transparentize($color: $primary-purple, $amount: 0.7);
  padding: 1em;

  & hr {
    width: 100%;
    height: 1px;
    background-color: $primary-purple;
    border: 0;
  }
}

.header {
  align-items: center;
}
