@import "../../styles/variables";

.text {
  font-family: $font-family;

  &.warning {
    color: red;
  }

  &.info {
    color: #ffa837;
  }

  &.success {
    color: #58c322;
  }

  &.strong {
    font-weight: 700;
  }

  &.tiny {
    font-size: 0.6rem;
  }

  &.small {
    font-size: 0.875rem;
  }

  &.large {
    font-size: 1.2rem;
  }

  &.extra-large {
    font-size: 1.5rem;
  }

  &.mono {
    font-variant-numeric: tabular-nums;
  }

  & a {
    color: $primary-purple;
  }

  &.secondary {
    opacity: 0.5;
  }

  &.alternate {
    color: $alternate-pink;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.justify {
    text-align: justify;
  }
}
