@import "../../styles/variables";

.container {
  position: relative;
}

.leaderboardTable {
  width: 600px;

  & th {
    vertical-align: middle;
  }
}

.positionColumn {
  width: 16px;
}

.highlight {
  color: $alternate-pink;

  & :hover {
    cursor: pointer;
  }
}

.seniorWatsonModal {
  max-width: 600px;

  & h1 {
    color: $alternate-pink;
  }
}

.logo {
  border-radius: 50%;
}

.pending {
  opacity: 0.5;
}

.tableLogo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.timeline {
  overflow-y: scroll;
  position: sticky;

  top: 0;
  right: -300px;

  max-height: 74vh;
}

.divisor {
  background-color: $alternate-pink !important;

  & td {
    height: 2px !important;
    padding: 0 !important;
  }
}
