@import "../../styles/variables";

.table {
  border-collapse: separate;
  border-spacing: 0 1em;

  &.small {
    border-spacing: 0 0.5em;
    font-size: 0.9em;

    & .td {
      padding: 0.5em;
    }
  }

  & .thead {
    color: white;
    font-weight: bold;
    text-align: left;
  }

  & .th {
    padding: 1em;
  }

  & .td {
    padding: 1em;
    vertical-align: middle;
  }

  & .tbody .tr {
    background-color: darken($color: $primary-purple, $amount: 35%);
  }

  &.selectable {
    & .tbody .tr {
      &:hover {
        cursor: pointer;
        background-color: darken($color: $primary-purple, $amount: 36%);
      }
    }
  }
}
