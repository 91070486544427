@import "../../styles/variables";

.container {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  .square {
    position: relative;
    background-color: transparentize($color: $primary-purple, $amount: 0.6);
    height: 100px;
    width: 100px;

    border: 2px solid $alternate-pink;
    transform: rotate(45deg);

    .iconContainer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      transform: rotate(-45deg);
    }
  }
}
