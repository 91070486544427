@import "../../styles/variables";

.container {
  width: 100%;
}

img.logo {
  border-radius: 50%;
}

hr {
  width: 100%;
  height: 1px;
  background-color: $primary-purple;
  border: 0;
  opacity: 0.5;
}

ul.errorList {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
  list-style-type: disc;
}

.middle {
  overflow: hidden;
}

.sidebar {
  padding: 0 0 0 2rem;
  flex-basis: 300px;
}

.c4Banner {
  background-color: transparentize($color: $primary-purple, $amount: 0.7);
  padding: 1em;
}

.formContainer {
  max-width: 520px;
}

.successModal {
  max-width: 520px;
}
